.snackbar {
  position: fixed;
  left: 50%;
  top: 150px;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
}

.snackbar .symbol {
  flex: 20%;
}

.snackbar .message {
  flex: 80%;
  text-align: start;
  /* font-weight: bold; */
}

#show {
  visibility: visible;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}
#hide {
  visibility: hidden;
}

@keyframes fadeIn {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
