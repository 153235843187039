.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modalContainer {
  width: 1000px;
  height: 700px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 0.5px solid #ccc;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.modalContainer .containerHeader {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.modalContainer .containerTitle {
  display: inline-block;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  height: 50px;
  padding-top: 10px;
  font-size: 25px;
  font-weight: 600;
}

.modalContainer .closeBtn {
  position: relative;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.modalContainer .body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow-y: auto;
}

.leftPanel {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  text-align: center;
  border: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.patternList {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  text-align: center;
  border: none;
  overflow-y: auto;
}

.patternItem {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  text-align: center;
  border-radius: inherit;
  padding: 0 0 0 0;
  border-bottom: 1px solid #ccc;
}

.patternItemText {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.patternItemText:hover {
  transform: scale(1.05);
}

.deleteBtn {
  width: 20px;
  height: 100%;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.deleteBtn:hover {
  transform: scale(1.05);
}

.addBtn {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #ccc;

  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  cursor: pointer;
}

.addBtn:hover {
  transform: scale(1.05);
}

.mainPanel {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0;
  border: 0.5px solid #ccc;
}

.panelHeader {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
  background-color: #f0f0f0;
  border-radius: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.panelTitle {
  font-size: 20px;
  text-align: center;
}

.panelTitleContainer {
  width: 150px;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.textInputContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: none;
  overflow-y: auto;
}
.textInput {
  width: 350px;
  height: 45px;
  margin: 1px;
  border: 1px solid #ccc;

  border-radius: 8px;
  font-size: 1.2rem;
  align-items: flex-start;
  text-align: left;
}

.saveBtn {
  width: 60px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgba(100, 149, 237);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.saveBtn:hover {
  background-color: rgb(110, 159, 255);
}
.patternItemContainerLabel {
  display: flex;
  justify-content: flex-start;
  margin: 15px;
  padding: 5px;
  font-family: "Pretendard-Regular";

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #ccc;
}
.patternItemContainer {
  width: 450px;
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
  padding: 5px;
}

.itemCard {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: rgba(250, 190, 50, 0.2);
  cursor: pointer;
}

.itemCard:hover {
  transform: scale(1.05);
  background-color: rgba(250, 190, 50, 0.5);
}

.cardLabel {
  font-size: 15px;
  font-weight: 400;
}

.patternResultContainer {
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 15px;
  padding: 5px;
}

.itemLabel {
  display: flex;
  width: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--Gray-500, #6b7280);
  font-family: "Pretendard-Regular";

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.patternResult {
  display: flex;

  width: 300px;
  height: 50px;
  margin: 1px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: left;
  align-items: center;
  padding: 15px;
  font-family: "Pretendard-Regular";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3px;
}

.patternDelBtn {
  display: flex;
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 8px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard-Regular";

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  background-color: #f0f0f0;
}

.patternDelBtn:hover {
  background-color: #ccc;
  transform: scale(1.05);
}

.LocationPatternContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
}

.LocationPatternSettingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 25px;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}

.patternPreviewContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.patternPreviewInputContainer {
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 10px;
  padding-top: 20px;
}

.patternPreviewInput {
  width: 45px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 15px;
  align-items: flex-start;
  text-align: center;
  cursor: pointer;
}

.inputBox {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: left;
}

.inputLabel {
  display: flex;
  width: 40px;
  flex-direction: column;
  text-align: left;
  font-size: 15px;
}

.patternPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 1px;
  text-align: left;
  align-items: center;
  padding-right: 15px;
  font-size: 25px;
  font-weight: 500;
  color: #3f464d;
}
.patternPreviewLabel {
  display: flex;
  width: 100%;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #3f464d;
}

.patternPreviewResult {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 3px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #3f464d;
}

.cellSchemeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.cellSchemeInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.cellSchemeInputBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

input[type="radio"] {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.cellSchemeImage {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
