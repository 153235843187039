.canvas {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  overflow: hidden;
  border: 1px solid #c3deff;
  position: absolute;
  top: 0;
  left: 0;
}
