/* Select.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  margin-top: 20px;
  margin-bottom: 10px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  align-content: space-between;
}

.filterName {
  width: 30%;
}

.input {
  width: 70%;
  margin-bottom: 0px;
}

.branchList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.scrollableList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scrollableList li {
  margin-bottom: 0px;
  padding: 4px;
  border: 1px solid #b4b4b8;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 10px;
}

ul {
  max-height: 300px; /* Set maximum height for the list */
  overflow-y: auto; /* Enable vertical scrollbar if necessary */
  border: 1px solid #b4b4b8;
}

.scrollableList .selectBtn {
  margin-left: 10px;
}

.newCenterRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.newCenterRegisterBtn {
  width: 200px;
  height: 40px;
}
