.panelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 0 0 0;
  padding: 5px 5px 5px 5px;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 0 0 10px 0;
  padding: 0 0 0 0;
}

.title {
  justify-content: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 30px;
  font-size: 20px;
  border-bottom: 1px solid #ccc;
}

.settingItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin: 10px 0 5px 0;
}

.settingItemTitle {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  width: 100%;
  padding: 5px 0 5px 5px;

  margin-top: 15px;
}

.settingItemContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 0 0;
  padding: 3px 5px 5px 5px;
}

.settingInput {
  width: 90%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.configItem {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  text-align: center;
  border-radius: inherit;
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.configTitle {
  width: 50%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.configInput {
  width: 100px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 15px;
  align-items: flex-start;
  text-align: left;
  padding-left: 10px;
}

.radioInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin: 0 0 0 0;
  padding-right: 5px;
}

.configSelectInputContainer {
  width: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 0 0 0 0;
}

.selectInput {
  width: 90%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.radioInput {
  width: 20px;
  height: 20px;
  margin: 5px;
  padding: 0 0 0 0;
}

.radioInputLabel {
  font-size: 13px;
  width: 20px;
  margin-right: 5px;

  padding: 0 0 0 0;
}

.saveBtnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 0;
}
.saveBtn {
  width: 100px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  background-color: #cee4ff;
  cursor: pointer;
}

.settingUnit {
  font-size: 13px;
  margin-right: 15px;
  width: 30px;
}

.settingInputRange {
  width: 100%;
  height: 20px;
  border: 1px solid #c3deff;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle {
  background-color: #2196f3;
}

input:focus + .toggle {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.circleButtonContainer {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-around;
  align-items: center;
  padding: 0px 0px 0px 0px;
}

.circleButton {
  display: inline-block;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #c3deff;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.poiContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 0 0;
  padding: 3px 5px 5px 5px;
}

.verticalRadioContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.radioInputContainer {
  display: flex;
  width: 100%;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
}

.radioInput {
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.radioInputLabel {
  font-size: 12px;
  width: 100%;
  cursor: pointer;
}

.radioInputContainer .radioInput:checked + .radioInputLabel {
  background-color: #c3deff;
}

.poiTitle {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  width: 100%;
  padding: 5px 0 5px 5px;
  margin-top: 15px;
}

.rackPreviewTitle {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 5px;
}
.rackPreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border: rgba(250, 190, 50, 1) 1px solid;
  background-color: #ffffff;
  overflow: auto;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(250, 190, 50, 0.1);
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-bottom: rgba(250, 190, 50, 1) 1px solid;
  background-color: rgba(250, 190, 50, 0.1);
  background-color: rgba(250, 190, 50, 0.1);
}

.cell {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 10px;
}
