.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 5px;
  width: 100%;
  justify-content: space-between;
  align-content: space-between;
}

.objectFilterIcon {
  padding: 0px;
  margin: 0px;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.input {
  width: 100%;
  height: 30px;
  margin-bottom: 0px;
}

.mainCategoryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 180px;
  padding: 0px;
  margin: 0px;
  border: 1px solid #f0f0f0;
}

.mainCategory {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
}

.lockButtonContainer {
  display: flex;
  width: 24px; /* 전체 너비의 1/10 */
  height: 30px;
  /* padding: auto; */
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.lockButton {
  width: 20px;
  height: 20px;
  margin: 0;
  padding-bottom: 2px;
  padding-top: 2px;
  display: flex;
}

.mainCategoryTitle {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  width: 60%; /* 전체 너비의 8/10 */
  height: 30px;
  display: flex;
  align-items: center;
}

.visibleOptionButtonContainer {
  display: flex;
  width: 24px; /* 전체 너비의 1/10 */
  height: 24px;
  /* padding: auto; */
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.visibleOptionButton {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  /* alpha */
}
.visibleOptionButton:hover {
  cursor: pointer;
  transition: transform 0.3s;
  transform: scale(1.1);
}

.ObjectListContainer {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  margin: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  border: 1px solid #f0f0f0;
  overflow-y: auto;
}

.ObjectList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25px;
  align-items: center;
}

.ObjectListItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ObjectListItem span {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  align-self: start;
  padding-left: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.ObjectListItem .visibleOptionButton {
  width: 15px;
  height: 15px;
}

.ObjectListItemAccordionItem {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  align-self: start;
  padding-left: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: none;
}
.RackListItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.RackListItem .ObjectListItem .itemLabel {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  align-self: start;
  padding-left: 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.cellItemContainer {
  width: 100%;

  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 25px;
}

.seeMoreButtonContainer {
  display: flex;
  width: 10px;
  /* padding: auto; */
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.seeMoreButton {
  width: 20px;
  height: 20px;
}
