* {
  box-sizing: border-box;
  margin: 0 auto;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.Editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-family: "Pretendard-Regular";
}

.EditorHeader {
  display: flex;
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}
.title {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  align-content: center;

  font-size: 25px;
  font-weight: 500;
  color: #3f464d;
  /* flex-wrap: wrap; */
  overflow: hidden;
  margin: 0;
}

.ToolBarContainer {
  background: #c3deff;
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  /* box-sizing: border-box; */
  /* margin: 0 auto; */
  z-index: 10;
}

.MainPanel {
  box-sizing: border-box;
  position: relative;

  margin: 0 auto;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.LeftPanelContainer {
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
}
.CanvasPanelContainer {
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 100%;
  /* background-color: #f0f0f0; */
}

.RightPanelContainer {
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
}
