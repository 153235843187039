.loadingContainer {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  cursor: wait;
}

.loadingSpinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #84b9fa; /* Change color as needed */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loadingInfo {
  margin-top: 20px; /* Adjust spacing as needed */
  font-size: 18px; /* Adjust font size as needed */
  color: #333; /* Adjust color as needed */
}

/* Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
