.flyoutContents {
  position: absolute;
  width: 170px;
  left: 35px;
  top: 49px;
  margin: 0;
  padding: 0;
  align-items: center;
  align-content: center;
  border: 1px solid #c3deff;
  border-radius: 3px;
  cursor: default;
}
.poiFlyoutContents {
  position: absolute;
  width: 180px;
  left: 35px;
  top: 49px;
  margin: 0;
  padding: 0;
  align-items: center;
  align-content: center;
  border: 1px solid #c3deff;
  border-radius: 3px;
  cursor: default;
}
.flyoutItem {
  display: flex;
  background: white;
  border: 1px solid white;
  flex-direction: column;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  margin: 0;
}

.flyoutLabel {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.penFillButton {
  /* toggle button */
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #c3deff;
  margin: 0;
  padding: 0;
}

.circleButtonContainer {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto;
  padding: 0px 0px 0px 0px;
}

.circleButton {
  display: inline-block;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #c3deff;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.Button {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #c3deff;
  border-radius: 0px;
  margin: 0;
  cursor: pointer;
  text-align: center;
}

.Button:hover {
  background-color: #c3deff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle {
  background-color: #2196f3;
}

input:focus + .toggle {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.strokeRange {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-around;
  align-items: center;
  /* margin: 10px auto; */
  cursor: pointer;
  padding: 0px 0px 0px 0px;
}

.flyoutSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #c3deff;
}

.settingContainer {
  display: flex;
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  padding: 0px 0px 0px 0px;
}

.settingLabel {
  width: 40px;
  font-size: 13px;
  margin-left: 10px;
}

.settingUnit {
  font-size: 13px;
  margin-right: 5px;
  width: 30px;
}

.settingInput {
  width: 60%;
  height: 20px;
  border: 1px solid #c3deff;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}
.verticalRadioContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.radioInputContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin: 0px auto;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  border: 1px solid #c3deff;
}

.radioInput {
  width: 15px;
  height: 15px;
  margin-left: 0px;
  margin-right: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radioInputContainer .radioInput:checked + .radioInputLabel {
  background-color: #c3deff;
}

.radioInputLabel {
  cursor: pointer;
}
