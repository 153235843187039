.toolButtonContainer {
  display: flex;
  height: 50px;
  width: 50px;
  box-sizing: border-box;
  align-items: center;
  margin: 0;

  /* border: 1px solid black; */
}

.toolButtonContainer:hover {
  cursor: pointer;
  background-color: #84b9fa;
}

.toolButtonContainer.active {
  background-color: #84b9fa;
  transform: scale(1.02);
  border-radius: 3px;
}

.button {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  margin: 0;
}

.toolButtonContainer:not(.active) .button:hover {
  transition: transform 0.3s;
  transform: scale(1.1);
}

.toolButton {
  width: 24px;
  height: 24px;
}

.flyout {
  display: flex;
  height: 100%;
  width: 30%;
  margin: 0;
  padding: 10px 10px 0px 0px;
  align-items: top;
  justify-content: center;
}

.flyout:not(.active):hover {
  /* animation down */
  transition: transform 0.5s;
  transform: translateY(10px);
}

.flyoutButton {
  width: 18px;
  height: 18px;
}

.helpButton {
  position: fixed;
  right: 1%;
  top: 86px;
  width: 20px;
  height: 20px;
}
